<script setup lang="ts">
import type { LandingPageBlok } from 'types/storyblok/pages/landing-page'
import StoryblokProductListBlock from '../../../storyblok/ProductListBlock.vue'
import { isProductListBlock, normalizeProductListBlock } from '~/types/storyblok/product-list-block'
import { WIDGET_DEFAULT_8_8_8_STORY_REF_PROD } from '~/configuration/global.configuration'

interface Props {
  blok: LandingPageBlok
}
const props = defineProps<Props>()

const storyblokStore = useStoryblokStore()
const gtm = useGTM()

const defaultWidgetStoryReference = WIDGET_DEFAULT_8_8_8_STORY_REF_PROD

const widgetId = props.blok.widget
  ? storyblokStore.state.widgets?.get(props.blok.widget)
  : storyblokStore.state.widgets?.get(defaultWidgetStoryReference)

// track the widgetId for landing page (used on homepage and campaign pages)
useProductStore().currentWidgetId = widgetId

const productBlocks = computed(() =>
  props.blok.body
    .filter(isProductListBlock)
    .map(b => ({ _uid: b._uid, ...normalizeProductListBlock(b, storyblokStore.state.categories) })),
)

const { data, pending, productsDistributionsMap } = useProductListBlock({
  productBlocks,
  widgetId,
  uuid: props.blok._uid,
})

function trackPageBlockClick(url: string, clickType: string, blockPosition: number) {
  gtm.pushPageBlockClick({ clickURL: url, clickType, clickBlockPosition: blockPosition })
}
</script>

<template>
  <main v-editable="blok">
    <div
      v-for="(component, index) in blok.body"
      :key="component._uid"
      data-test="landing-page-block"
    >
      <StoryblokProductListBlock
        v-if="isProductListBlock(component) && widgetId"
        :blok="component"
        :products="!data && pending ? [] : productsDistributionsMap.get(component._uid)"
        :data-position="index"
        @track-block-click="
          (url: string, clickType: string) => trackPageBlockClick(url, clickType, index)
        "
      />
      <component
        :is="component.component"
        v-else
        :key="component._uid"
        :blok="component"
        :data-position="index"
        @track-block-click="
          (url: string, clickType: string) => trackPageBlockClick(url, clickType, index)
        "
      />
    </div>
  </main>
</template>
